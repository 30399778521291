import { Link, useLocation } from "react-router-dom";
import { Backward } from "../images";
import "./index.css";

const PreCheckout = () => {
  const location = useLocation();
  const state = location?.state;

  return (
    <div>
      <div className="pre-checkout-header">
        {/* <Link to="/transfer">
          <img
            id="pre-checkout-backward-img"
            src={Backward}
            // onClick={handleOrderClick}
          />
        </Link> */}
        <div></div>
        <div></div>
      </div>
      <div className="pre-checkout-input-area">
        <div>
          <div className="pre-checkout-header">Thanks for ordering minies</div>
          <div className="pre-checkout-text">
            We are currently processing your transaction, and you will receive a
            text message notification once the confirmation is complete. <br />{" "}
            <br /> Alternatively, you can visit the minies stand in person to
            verify your order at Unbound.
          </div>
          <div className="final-order">
            <div className="ordered-cont">
              <div className="order">ORDER</div>
              <div className="pre-checkout-order-text">
                Yam and sauce with chicken
              </div>
              <div className="pre-checkout-confirmatory-amount">
                <div className="confirmatory-amount-input">Amount</div>
              </div>
              <div className="pre-checkout-confirmatory-amount-figure">
                <div className="confirmatory-amount-figure-input">
                  NGN {state}.00
                </div>
              </div>
            </div>
          </div>
          <div className="pre-checkout-order-button">
            <Link to="/checkout">
              <button id="order-button">Download Tripplex</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreCheckout;
