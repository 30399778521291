import axios from "axios";

// const baseUrl = "https://residently.herokuapp.com/api/v1";
const baseUrl = "https://api.tripplex.co/api/v1";

const processData = (body) => {
  const endpoint = `${baseUrl}/orders/create`;
  return axios.post(endpoint, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const verifyTransaction = (reference) => {
  const endpoint = `${baseUrl}/orders/transaction/verify/${reference}`;
  return axios.get(endpoint, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export { processData, verifyTransaction };

// "https://url.com/test"
