import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { Backward, Document } from "../images";
import { Link, useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SecureLS from "secure-ls";

const TransferPage = () => {
  const location = useLocation();
  const state = location?.state;

  // console.log(state);
  // console.log(state.amount);

  const ls = new SecureLS();
  const nav = useNavigate();
  const order = ls.get("order");

  useEffect(() => {
    if (!order) {
      nav("/");
    }
  }, []);
  return (
    <div>
      <div className="header">
        <div className="transfer-header">
          <Link to="/method">
            <img
              id="backward-img"
              src={Backward}
              // onClick={handleOrderClick}
            />
          </Link>
        </div>
        <div className="transfer-minies">
          <div className="page-header">Fund Wallet</div>
        </div>
        <div></div>
      </div>
      <div className="input-area">
        <div>
          <div className="transfers">
            <div className="bank-account">Bank Account</div>
            <div className="transfer-alt">
              Make a transfer of N{state}.00 to the account number below
            </div>
            <div className="account">
              <div className="transfer-name">Account name</div>
              <div className="transfer-input">Tripplex/Oluwatomini Adebayo</div>
              <div className="transfer-name">Account number</div>
              <div className="transfer-number-input">
                <div className="transfer-input">5585234275</div>
                <CopyToClipboard
                  text={"5585234275"}
                  onCopy={(text, result) => {
                    console.log("this", result);
                    alert(`copied to clipboard`);
                  }}
                >
                  <img id="document-img" src={Document} />
                </CopyToClipboard>
              </div>
              <div className="transfer-name">Bank name</div>
              <div className="transfer-input">Kredi money microfinance</div>
            </div>
          </div>
        </div>
      </div>
      <div className="transfer-button">
        <Link to="/pre-checkout" state={state}>
          <button id="order-button">I've Paid</button>
        </Link>
      </div>
    </div>
  );
};

export default TransferPage;
