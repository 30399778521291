import "./index.css";
import {
  Minies,
  orderOne,
  orderTwo,
  orderThree,
  Forward,
  Tripplex,
} from "../images";
import { useNavigate } from "react-router-dom";
import { HeaderBackground } from "../assets";
import { useEffect, useState } from "react";
import SecureLS from "secure-ls";

const WelcomePage = () => {
  const navigate = useNavigate();

  const ls = new SecureLS();

  const [pageToRender, setPageToRender] = useState("");

  useEffect(() => {
    ls.remove("order");
  }, []);
  return (
    <>
      {pageToRender === "" && (
        <div>
          <div>
            {/* <div>
          <img src={layer} />
        </div> */}
            <div className="background">
              <div className="background-page">
                <img
                  src={HeaderBackground}
                  className="header-img"
                  alt="header background"
                />
                <div className="page-content">
                  <div className="background-header">
                    <img id="minies-img" src={Minies} />
                  </div>
                  <div className="background-headerr">
                    Place your minies order
                  </div>
                  <div className="text">
                    No queues, just pay and pick (up) your minies!
                  </div>
                  <div className="items-list">
                    {[
                      {
                        title: "Yam and sauce with chicken",
                        amount: "1200.00",
                        oldprice: "₦1500",
                        newprice: "1200",
                        img: orderOne,
                      },
                      {
                        title: "Potato fries and sauce with chicken",
                        amount: "1200.00",
                        oldprice: "₦1500",
                        newprice: "1200",
                        img: orderTwo,
                      },
                      {
                        title: "Yam and sweet potato fries with chicken",
                        amount: "1500.00",
                        oldprice: "₦2000",
                        newprice: "1500",
                        img: orderThree,
                      },
                    ].map((element, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            ls.set("order", element);
                            navigate("/order", { state: element });
                          }}
                          className="items-container"
                        >
                          <img id="order-img" src={element.img} />
                          <div className="welcome-page-text">
                            <div className="text-line">
                              <div className="item-text-one">
                                {element.title}
                              </div>

                              <img id="forward-img-one" src={Forward} />
                            </div>
                            <div className="prices">
                              <div className="item-price">
                                Cost <span>{element.oldprice}</span>
                              </div>
                              <div className="new-item-price">
                                ₦{element.newprice}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="powered-by">Powered by Tripplex</div>
                  <div className="tripplex">
                    <img id="tripplex-img" src={Tripplex} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default WelcomePage;
