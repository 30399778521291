import { useState } from "react";
import { Backward, check, Forward } from "../images";
import "./index.css";
import { Link } from "react-router-dom";

const ConfirmatoryPage = () => {
  return (
    <div>
      <div className="header">
        <Link to="/transfer">
          <img
            id="backward-img"
            src={Backward}
            // onClick={handleOrderClick}
          />
        </Link>
        <div></div>
        <div></div>
      </div>
      <div className="check-cont">
        <div className="confirmatory-check">
          <img id="check" src={check} />
        </div>
      </div>
      <div className="confirmatory-text-cont">
        <div className="confirmatory-text">
          Please hold on while we confirm your transaction
        </div>
      </div>
      <div className="confirmatory-amount">
        <div className="confirmatory-amount-input">Amount</div>
      </div>
      <div className="confirmatory-amount-figure">
        <div className="confirmatory-amount-figure-input">NGN 1100.00</div>
      </div>
      <div className="refresh">
        <div className="refresh-input">Refreshing </div>
        <div className="refreshing-input"> in 00:13</div>
      </div>
      <Link to="/confirmed">
        <img src={Forward} />
      </Link>
    </div>
  );
};

export default ConfirmatoryPage;
