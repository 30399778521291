import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import App from "./App";
import WelcomePage from "./Welcome Page";
import OrderPage from "./Order Page";
import TransferPage from "./TransferPage";
import PaymentMethod from "./Payment Method";
import ConfirmatoryPage from "./Confirmatory Page";
import ConfirmedPage from "./Confirmed Page";
import CheckoutPage from "./Checkout Page";
import PreCheckout from "./Pre Checkout Page";

const el = document.getElementById("root");
const root = ReactDOM.createRoot(el);

const router = createBrowserRouter([
  {
    path: "/",
    element: <WelcomePage />,
  },
  {
    path: "/order",
    element: <OrderPage />,
  },
  {
    path: "/transfer",
    element: <TransferPage />,
  },
  {
    path: "/method",
    element: <PaymentMethod />,
  },
  {
    path: "/confirmatory",
    element: <ConfirmatoryPage />,
  },
  {
    path: "/confirmed",
    element: <ConfirmedPage />,
  },
  {
    path: "/pre-checkout",
    element: <PreCheckout />,
  },
  {
    path: "/checkout",
    element: <CheckoutPage />,
  },
]);
root.render(<RouterProvider router={router} />);
