import { useEffect } from "react";
import { Backward, Forward, Home, Payment } from "../images";
import "./index.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";
import { verifyTransaction } from "../api";
import SecureLS from "secure-ls";

const PaymentMethod = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location?.state;
  console.log("stty MAin state", state);
  // const url = state?.data?.authorization_url;
  // console.log('stty', url);

  const config = {
    reference: new Date().getTime().toString(),
    email: state?.email,
    amount: Number(state?.amount) * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: "pk_live_cface8e6b612ed0ce3e80eae2101b39d8165d1ff",
  };

  // you can call this function anything
  const onSuccess = ({ reference }) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log("ref-succ", reference);
    verifyTransaction(reference)
      .then(() => {
        console.log("checkout");
        navigate("/confirmed", { state: {amount: state?.amount} });
      })
      .catch((error) => console.log(error))
      .finally(() => {});
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const initializePayment = usePaystackPayment(config);

  const handleClick = () => initializePayment(onSuccess, onClose);


  const ls = new SecureLS();
  const nav = useNavigate();
  const order = ls.get("order");

  useEffect(() => {
    if (!order) {
      nav("/");
    }
  }, []);

  return (
    <div>
      <div className="header">
        <Link to="/order">
          <img id="backward-img" src={Backward} />
        </Link>
        <div className="minies">
          <div className="page-header">Payment Method</div>
        </div>
        <div></div>
      </div>
      <div className="input-area">
        <div>
          <div className="payment">
            <div className="payment-text">Select Payment Destination</div>
          </div>
          <div className="container">
            <div>
              <div className="cont">
                <button
                  onClick={() =>
                    navigate("/transfer", { state: state?.amount })
                  }
                  className="button-container"
                >
                  <div className="image-cont">
                    <img id="home-image" src={Home} />
                  </div>
                  <div className="transfer-items">
                    <div className="pay">Pay With Transfer</div>
                    <div className="alt">
                      Send Money to the bank account of your choice
                    </div>
                  </div>
                  {/* <Link to="/transfer"> */}
                  <img id="forward-img-one" src={Forward} />
                  {/* </Link> */}
                </button>
              </div>
              <div className="cont">
                <button className="button-container" onClick={handleClick}>
                  <div className="image-cont">
                    <img id="payment-image" src={Payment} />
                  </div>

                  <div className="transfer-items">
                    <div className="pay">Pay With Card</div>
                    <div className="alt">
                      Use bank card to top up your wallet.
                    </div>
                  </div>
                  {/* <Link to={url}> */}
                  <img id="forward-img-one" src={Forward} />
                  {/* </Link> */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
