import { AppStoreIcon, GooglePlayIcon, CheckoutIcon } from "../images";
import "./index.css";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";

const CheckoutPage = () => {
  const ls = new SecureLS();
  const nav = useNavigate();
  const order = ls.get("order");

  useEffect(() => {
    if (!order) {
      nav("/");
    }
  }, []);
  return (
    <div>
      <div className="checkout">
        <img id="checkouts" src={CheckoutIcon} />
      </div>
      <div className="checkout">
        <div className="checkout-text">Download the Tripplex app</div>
      </div>
      <div className="final-checkout-text">
        <div className="checkout-texts">
          The easiest way to pay your bills is just one click away.
        </div>
      </div>
      <div className="final-checkout-text">
        <div className="app-store-icon-conts">
          <img
            id="store-icon-background"
            src={AppStoreIcon}
            // URL="https://www.apple.com/app-store/"
          />
        </div>
        <div> </div>
        <div className="play-store-icon-conts">
          <img id="store-icon-background" src={GooglePlayIcon} />
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
