import { useState, useEffect } from "react";
import "./index.css";
import { Backward, Downward, iconminus, iconplus } from "../images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { processData } from "../api";
import SecureLS from "secure-ls";

const OrderPage = () => {
  const [numberToOrder, setNumberToOrder] = useState(1);

  const nav = useNavigate();

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userNumber, setUserNumber] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const state = location?.state;
  const ls = new SecureLS();

  const handleNameChange = (event) => {
    setUserName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setUserEmail(event.target.value);
  };
  const handleNumberChange = (event) => {
    setUserNumber(event.target.value);
  };

  const handleClick = () => {
    if (userName.trim().length <= 1) {
      setNameError(true);
    } else if (userEmail.trim().length <= 1 && !userEmail.includes("@")) {
      setEmailError(true);
    } else if (userNumber.length !== 10) {
      setNumberError(true);
    } else {
      setLoading(true);

      const body = {
        amount: String(amount),
        item: state?.title,
        quantity: numberToOrder,
        email: userEmail,
        fullname: userName,
        phone: `0${userNumber}`,
      };
      console.log("body", body);

      processData(body)
        .then((res) => {
          console.log("API response", res);
          nav("/method", { state: body });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const order = ls.get("order");
    if (!order) {
      nav("/");
    }
  }, []);
  useEffect(() => {
    if (!location.state) {
      return nav("/");
    }
    const title = location.state.title;
    const amount = location.state.amount;

    setTitle(title);
    setAmount(amount);
  }, [location]);

  useEffect(() => {
    if (userName.trim().length > 1) {
      setNameError(false);
    }
  }, [userName]);

  useEffect(() => {
    if (userEmail.trim().length > 1 && userEmail.includes("@")) {
      setEmailError(false);
    }
  }, [userEmail]);

  useEffect(() => {
    if (userNumber.length === 10) {
      setNumberError(false);
    }
  }, [userNumber]);

  useEffect(() => {
    if (amount) {
      console.log("numberToOrder", numberToOrder, amount, Number(amount));
      const newAmount = numberToOrder * Number(location.state.amount);
      setAmount(newAmount);
    }
  }, [numberToOrder]);

  const handleAddQuantity = () => {
    setNumberToOrder(numberToOrder + 1);
  };

  const handleReduceQuantity = () => {
    if (numberToOrder > 1) {
      setNumberToOrder(numberToOrder - 1);
    }
  };

  const handleInputNumbersOnly = (e) => {
    if (isNaN(e.key) && e.key !== "Backspace" && e.key !== "Enter") {
      e.preventDefault();
    }
  };

  return (
    <div>
      <div className="header">
        <Link to="/">
          <img
            id="backward-img"
            src={Backward}
            // onClick={handleOrderClick}
          />
        </Link>
        <div className="minies">
          <div className="page-header">Minies</div>
        </div>
        <div></div>
      </div>
      <div className="input-area">
        <div className="input-cont">
          <div className="ordered-text">{title}</div>
          {/* {error && <h2 className="error-text">{error}</h2>} */}
          <div className="amount">Amount</div>
          <div className="amount-inputs">
            <div className="amount-input">{amount}</div>
            <div className="line"></div>
            <div className="currency">NGN</div>
          </div>
          <div className="quantity">Quantity</div>
          <div className="quantity-control">
            <div className="subtract" onClick={handleReduceQuantity}>
              <img id="arithicon" src={iconminus} />
            </div>
            <div className="number">{numberToOrder}</div>
            <div className="add" onClick={handleAddQuantity}>
              <img id="arithicon" src={iconplus} />
            </div>
          </div>
          <div className="name">Full Name</div>
          {/* <div className="inputs">
            <input placeholder="Name" />
          </div> */}
          {!nameError ? (
            <div className="inputs">
              <input
                placeholder="Name"
                value={userName}
                onChange={handleNameChange}
              />
            </div>
          ) : (
            <div className="input-error">
              <input placeholder="Enter a name" onChange={handleNameChange} />
            </div>
          )}
          <div className="name">Email address</div>
          {/* <div className="inputs">
            <input value={userEmail} onChange={handleEmailChange} placeholder="@gmail.com" />
          </div> */}
          {!emailError ? (
            <div className="inputs">
              <input
                placeholder="@gmail.com"
                value={userEmail}
                onChange={handleEmailChange}
              />
            </div>
          ) : (
            <div className="input-error">
              <input
                placeholder="Enter a valid email address"
                onChange={handleEmailChange}
              />
            </div>
          )}
          <div className="name">Phone Number</div>
          <div className="inputs">
            <div>+234</div>
            <div>
              <img id="downward" src={Downward} />
            </div>
            <div>
              <div className="number-line"></div>
            </div>

            {/* <input value={userNumber} onChange={handleNumberChange} placeholder="Phone Number" type="number" /> */}
            {!numberError ? (
              <input
                onKeyDown={handleInputNumbersOnly}
                className="number-input"
                placeholder="Phone Number"
                value={userNumber}
                onChange={handleNumberChange}
              />
            ) : (
              <input
                onKeyDown={handleInputNumbersOnly}
                className="number-input-error"
                placeholder="Enter a valid number"
                onChange={handleNumberChange}
              />
            )}
          </div>
        </div>
      </div>
      <div className="button">
        {/* <button onClick={() => handleClick()} id="order-button">
          Continue
        </button> */}
        <button onClick={handleClick} id="order-button">
          {!loading ? "Continue" : "Processing.."}
        </button>
      </div>
    </div>
  );
};
export default OrderPage;
