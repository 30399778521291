import { Backward, ConfirmedIcon } from "../images";
import "./index.css";
import { Link, useLocation } from "react-router-dom";

const ConfirmedPage = () => {
  const location = useLocation();
  const amount = location?.state?.amount;

  return (
    <div>
      <div className="header">
        <Link to="/confirmatory">
          <img
            id="backward-img"
            src={Backward}
            // onClick={handleOrderClick}
          />
        </Link>
        <div></div>
        <div></div>
      </div>
      <div className="check-cont">
        <div className="confirmed-check">
          <img id="conf-check" src={ConfirmedIcon} />
        </div>
      </div>
      <div className="confirmatory-text-cont">
        <div className="confirmed-text">
          Your order has been placed succesfully
        </div>
      </div>
      <div className="confirmatory-text-cont">
        <div className="order-text">
          We will notify you once the order is ready
        </div>
      </div>
      <div className="confirmatory-amount">
        <div className="confirmatory-amount-input">Amount</div>
      </div>
      <div className="confirmatory-amount-figure">
        <div className="confirmatory-amount-figure-input">NGN {amount}</div>
      </div>
      <div className="transfer-button">
        <Link to="/checkout">
          <button id="order-button">Download Tripplex</button>
        </Link>
      </div>
    </div>
  );
};

export default ConfirmedPage;
